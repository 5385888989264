import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { ThemeProvider } from '@mui/material/styles';

function InstructionsComponent(props) {
    return (
        <ThemeProvider theme={props.theme}>
            <Grid container spacing={2} className="instructions">
                <Grid item xs={12}>
                    <Typography variant="body1" sx={{marginTop: '2em'}}>
                    Choose your App Privacy Report file to view the unblocked trackers collecting data on your device and what apps they can be found in. We don’t track, collect, or store any data from this website. The JSON file is not uploaded to our servers and is only processed locally on your machine. The application does initiate a network request to download the app details (name, icon, etc.) from our servers. None of these network requests are logged and they are only used for the functionality of this website. View our privacy policy here: <a href="https://disconnect.me/privacy">https://disconnect.me/privacy</a>
                    </Typography>
                    <Divider  sx={{margin: '2em 0'}}></Divider>
                    <Typography variant="h5" sx={{marginTop: '1.5em', fontWeight: '500'}}>
                        To download your App Privacy Report:
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography style={{fontWeight: 600, margin: '1em 0'}}>
                        1. Open Privacy Settings
                    </Typography>
                    <img src="/img/step1.png"></img>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography style={{fontWeight: 600, margin: '1em 0'}}>
                        2. Tap App Privacy Report
                    </Typography>
                    <img src="/img/step2.png"></img>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography style={{fontWeight: 600, margin: '1em 0'}}>
                        3. Turn on App Privacy Report
                    </Typography>
                    <img src="/img/step3.png"></img>

                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography style={{fontWeight: 600, margin: '1em 0'}}>
                        4. Export App Privacy Report
                    </Typography>
                    <img src="/img/step4.png"></img>

                </Grid>
            </Grid>
            
        </ThemeProvider>
    )
}

export default InstructionsComponent;