import './App.css';
import React from 'react';
import _ from 'underscore';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from'@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import InstructionsComponent from './Instructions';
import AppList from './AppList'
import ListFilterComponent from './ListFilter';
import ConnectionsTable from './ConnectionsTable';



const ndjsonParser = require('ndjson-parse');

const Input = styled('input')({
  display: 'none',
});

const theme = createTheme({
  typography: {
    fontFamily: [
      'Avenir Next',
      'sans-serif',
    ].join(','),
  },
  palette: {
    breadcrumb: {
      main: '#666666',
      secondary: '#000000'
    },
    primary: {
      main: '#47a580',
      darker: '#053e85',
    }
  }
});

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


class App extends React.Component {
  constructor(props) {
    super(props);

    this.handleFileChange = this.handleFileChange.bind(this)
    this.selectApp = this.selectApp.bind(this)
    this.protectionListChange = this.protectionListChange.bind(this)

    this.state = {
      isFileUploaded: false,
      domains: [],
      file: "",
      applications: [],
      selectedApp: "",
      blocklist: {},
      default_protection_list: [],
      curated_lists: {},
      current_blocklist: [],
      selected_curated_blocklist: [],
      isLoading: false,
      trackers: [],
      trackers_blocked: [],
      showBlockedAppsOnly: true
    }

  }

  componentDidMount() {
    this.setState({isLoading:true})
    var list_url = process.env.NODE_ENV === 'development' ? 'http://localhost:5000/list/tv2' : '/list/tv2';

    fetch(list_url).then((response) => response.json()).then(data => {

      var default_blocklist = data.blocklist;

      for (const list in data.curated) {
        if(list != 'cryptomining') {
          for(const lvl in data.curated[list]) {
            var n_list = data.curated[list][lvl]

            default_blocklist = default_blocklist.concat(n_list)
          }
        }
        
      }

      this.setState({
        blocklist:default_blocklist,
        current_blocklist: default_blocklist,
        curated_lists: data.curated,
        default_protection_list: data.blocklist,
        isLoading: false
      })

    })


  }

  selectApp(e) {

    if(e !== ""){
      this.setState({selectedApp: e.id})
    } else {
      this.setState({selectedApp: ""})
    }
  }


  handleFileChange(e) {

    this.setState({isLoading:true})

    var that = this

    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = function(e) {
      var parsed = ndjsonParser(e.target.result)

      var filtered = _.filter(parsed, (d) => {
        // Only select network activity for now
        return d.type === "networkActivity"
      })
      

      var unique_bundle_id = _.uniq(filtered, function(d) { return d.bundleID });

      getAllBundlesInfo(unique_bundle_id);

      async function getAllBundlesInfo(bundles) {

        try {
          let bundle_url = process.env.NODE_ENV === 'development' ? `http://localhost:5000/bundle/` : `/bundle/`;
          var data = await Promise.all(bundles.map(bundle => {

            var b_id = bundle.bundleID;

            if(b_id.indexOf('/') > 0) {
              var new_id = b_id.slice(b_id.indexOf('/')+1,b_id.length);
              b_id = new_id;
            }

            return fetch(`${bundle_url}${b_id}`).then((response) => response.json()).then(data => {
              if(data.resultCount > 0) {
                return {
                  id: b_id,
                  image: data.results[0].artworkUrl100,
                  name: data.results[0].trackName,
                  trackers: []
                }
              } else if (b_id.indexOf('com.apple.') > -1) {
                // Apply system app
                var app_name = b_id.split('.')[2];
                app_name = app_name.charAt(0).toUpperCase() + app_name.slice(1)

                return {
                  id: b_id,
                  image: `/img/${app_name.toLowerCase()}-app-icon.svg`,
                  name: app_name,
                  trackers: []
                }

              } else {
                console.log("Not normal, not apple: ",b_id)
                return {
                  id: b_id,
                  image: "/img/default-app-icon.svg",
                  name: "iOS System",
                  trackers: []
                }
              }
            })
          }))


          var trackers = that.state.trackers;

          _.each(filtered, (domain,i) => {
            filtered[i].isTracker = false
            filtered[i].id = i

            // Split the domain
            var split_domain = domain.domain.split('.');
            var length = split_domain.length;
        

            // Loop over the domain including subdomains and test each
            while (length >= 2) {

              let current_domain = split_domain.join('.')
              var isTracker = _.includes(that.state.current_blocklist, current_domain);

              if(isTracker) {
                
                filtered[i].isTracker = true;
                filtered[i].isBlocked = false;
                var app_index = _.findIndex(data, {id: domain.bundleID});

                for(var x=filtered[i].hits; x>0; x--) {
                  trackers.push(filtered[i])
                  data[app_index].trackers.push(current_domain)
                }
              }

              length--;
              split_domain.shift();
            }
    

            _.find(data, (b_info) => {
              if(b_info.id === domain.bundleID) {
                filtered[i].name = b_info.name;
                filtered[i].image = b_info.image;
              }
            })
          })

          that.setState({
            isFileUploaded: true,
            trackers: trackers,
            domains: filtered,
            applications: data,
            isLoading: false
          })

          console.log(that.state)

        } catch (error) {
          console.log(error)
        }
      }


    }

  }
  
  protectionListChange(e) {
    var value = e.target.value;
    var n_value = typeof value === 'string' ? value.split(',') : value;
    var n_blocklist = []

    console.log(n_value)

    if(n_value.length > 0) {

      for(var x=0; x < n_value.length; x++){

        if(n_value[x] != 'Disconnect default protection') {
          var separated_list_name = n_value[x].toLowerCase().split(' ');

          var n_list = this.state.curated_lists[separated_list_name[0]][separated_list_name[1]];
          
          n_blocklist = n_blocklist.concat(n_list)

        } else {
          n_blocklist = n_blocklist.concat(this.state.default_protection_list)
        }

        
  
      }
      // n_blocklist = this.state..blocklist.concat(n_blocklist)

    } 

    console.log(n_blocklist)
      var current_domain_list = this.state.domains
      var current_applications = this.state.applications
      var trackers_blocked = [];



      // Reset tracker count so we can test for trackers against the new blocklist
      _.each(current_applications, (application,i) => {
        current_applications[i].trackers = [];
      })
  
      _.each(current_domain_list, (domain,i) => {
        domain.isTracker = false;
        domain.isBlocked = false;
  
        // Split the domain
        var split_domain = domain.domain.split('.');
        var length = split_domain.length;
    
  
        // Loop over the domain including subdomains and test each
        while (length >= 2) {
  
          let current_domain = split_domain.join('.')
          var isTracker = _.includes(this.state.blocklist, current_domain);
  
          if(isTracker) {
            
            current_domain_list[i].isTracker = true;

            var isBlocked = _.includes(n_blocklist, current_domain);
            var app_index = _.findIndex(current_applications, {id: domain.bundleID});


            if(isBlocked) {
              current_domain_list[i].isBlocked = true
              for(var x=current_domain_list[i].hits; x>0; x--) {
                trackers_blocked.push(current_domain)
              }
            } else {
              for(var x=current_domain_list[i].hits; x>0; x--) {
                current_applications[app_index].trackers.push(current_domain)
              }
            }
            

            
          }
  
          length--;
          split_domain.shift();
        }
      })

      
      this.setState({
          selected_curated_blocklist: n_value,
          current_blocklist: n_blocklist,
          trackers_blocked: trackers_blocked,
          domains: current_domain_list,
          applications: current_applications
      })

    
  }

  render() {

    var list = this.state.domains
    var applications = this.state.applications
    var selectedAppName= ""
    
    if(this.state.selectedApp !== "") {
      list = _.filter(list, (d) => {
        return d.bundleID === this.state.selectedApp
      })

      selectedAppName = _.find(applications, (a) => {
        return a.id === this.state.selectedApp
      }).name

    }

    if(this.state.showBlockedAppsOnly) {

      applications = _.filter(applications, (a) => {
        return a.trackers.length > 0
      })

      list = _.filter(list, (d) => {
        var a = _.find(applications, {id: d.bundleID})
        if(a != undefined) {
          return d
        }
      })

    }

    var apps_w_trackers = _.filter(applications, (a) => {
      return a.trackers.length > 0
    })


    var trackersFound = this.state.trackers.length - this.state.trackers_blocked.length
    

    return(
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container fixed>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Grid container spacing={2} >
          <Grid item xs={12} md={6}>
            <Stack sx={{marginTop: '3em'}} direction="row" alignItems="center" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
              <div id="header">
                <img src="/img/disconnect-d-logo.svg" alt="Disconnect logo"></img>
              </div>
              <Typography variant="h5">
              Disconnect iOS App Tracker Report
              </Typography>

            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            { this.state.isFileUploaded === false && 

            <label htmlFor="contained-button-file">
              <Input accept=".ndjson" id="contained-button-file" onChange={this.handleFileChange} type="file" />
              <Button disableElevation variant="contained" component="span" sx={{float: 'right', width: '80%', marginTop: '3em'}} size="large" className="choose-file-btn">
                Choose file
              </Button>
            </label>

            } 

            { this.state.isFileUploaded &&
              <ListFilterComponent onListChange={this.protectionListChange} selectedLists={this.state.selected_curated_blocklist} />
            }
          </Grid>
        </Grid>

        { this.state.isFileUploaded === false && 

          <InstructionsComponent theme={theme}></InstructionsComponent>
        }

      
          
        { this.state.isFileUploaded &&
          <>
            <Divider sx={{margin: '2em 0'}}></Divider>

            <Grid container spacing={2} className="high-lvl-stats">
              <Grid item xs={4}>
                  <h2>{trackersFound}<br/><small>Unblocked trackers</small></h2>
              </Grid>
              <Grid item xs={4}>
                  <h2>{this.state.applications.length}<br /><small>Apps in report</small> </h2>
                </Grid>
              <Grid item xs={4}>
                  <h2>{apps_w_trackers.length}<br/><small>Apps with unblocked trackers</small></h2>
              </Grid>

              <Grid item xs={12}>
              <Typography sx={{textAlign: 'center', background: '#f6f6f6', padding: '0.5em', borderRadius: 1}}>
                * Trackers blocked by Disconnect or other privacy tools will not appear in Privacy Report.
                </Typography>
              
              </Grid>
            </Grid>

            <Divider sx={{margin: '2em 0 1em 0'}}></Divider>

            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb" className="breadcrumbs"
                >
                  <Button color="breadcrumb" onClick={() => this.selectApp("")}>All apps</Button>
                  { this.state.selectedApp !== "" &&
                    <Button>{selectedAppName}</Button>
                  }
                </Breadcrumbs>
              </Grid>
              <Grid item xs={0} md={4}>
                <FormGroup sx={{float:'right'}}>
                  <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} checked={this.state.showBlockedAppsOnly} onChange={() => this.setState({showBlockedAppsOnly: !this.state.showBlockedAppsOnly, selectedApp: ""})} />} label=" Apps with unblocked trackers only" />
                </FormGroup>
              </Grid>
              <Grid item xs={12} >
                <AppList applications={applications} selectedApp={this.state.selectedApp} appChange={this.selectApp}/>
              </Grid>

              <Grid item xs={12} sx={{margin: '2em 0 0 0'}}>
                <ConnectionsTable connections={list}/>
              </Grid>
            </Grid>
          </>
        }
        <Divider sx={{margin: '2em 0'}}></Divider>
        <Grid item xs={12}>
          <Typography sx={{fontSize: '0.9em', color: '#919191', margin: '0 0 2em 0'}} >
            ©2021 Disconnect, Inc 
          </Typography>
        </Grid>
        </Container>
      </ThemeProvider>
    )
  }
}

export default App;