import React, {useState,useEffect,useRef} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import ErrorIcon from '@mui/icons-material/Error';
import _ from 'underscore'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import Tooltip from '@mui/material/Tooltip';

import { Typography } from '@mui/material';

var orig_columns = [
    { field: 'isTracker', headerName: 'Tracker',
        flex: 0.3,
        renderCell: (params) => {
            var isTracker = params.row.isTracker
            var isBlocked = params.row.isBlocked
            return (
                <>
                    { isTracker && !isBlocked &&
                        <ErrorIcon sx={{
                            mx: 'auto'}} color="error"/>
                    }

                    { isTracker && isBlocked &&
                        <Tooltip placement="right" title="Blocked tracker">
                            <BlockOutlinedIcon sx={{mx: 'auto'}} color="error"/>

                        </Tooltip>
                    }
                </>
            )
        },
        
    },
    { field: 'hits', headerName: 'Hits', flex: 0.2},
    { field: 'domain', headerName: 'Domain called', flex: 1 },
    { field: 'name', headerName: 'Application', flex:1 },
    { field: 'domainType', headerName: 'Domain type', flex:0.2 },
    { field: 'initiatedType', headerName: 'Initiated type', flex: 0.2},
    { field: 'context', headerName: 'Context', flex:0.2 },
  ];

var mobile_columns = [
    { field: 'isTracker', headerName: 'Tracker',
        flex: 0.8,
        renderCell: (params) => {
            var isTracker = params.row.isTracker
            return (
                <>
                    { isTracker &&
                        <ErrorIcon sx={{
                            mx: 'auto'}} color="error"/>
                    }
                </>
            )
        },
        
    },
    { field: 'hits', headerName: 'Hits', flex: 0.2, hide: true},
    { field: 'domain', headerName: 'Domain', flex: 1 },
    { field: 'name', headerName: 'Application', flex:0.8 },
    { field: 'domainType', headerName: 'Domain type', flex:0.2, hide: true},
    { field: 'initiatedType', headerName: 'Initiated type', flex: 0.2, hide: true},
    { field: 'context', headerName: 'Context', flex:0.2, hide: true },
]

function ConnectionsTable(props) {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const prevMatchesRef = useRef(matches)
    const [columns, setColumns] = useState(matches ? orig_columns : mobile_columns);
    const [selectedDomain, setSelectedDomain] = useState();
    const [open, setOpen] = React.useState(false);
    const [dialogTitle, setDialogTitle] = React.useState("Wallet");
    const [dialogContent, setDialogContent] = React.useState("Dialog content");
    const [dialogImage, setDialogImage] = React.useState("https://is1-ssl.mzstatic.com/image/thumb/Purple125/v4/3c/99/df/3c99df2b-653a-ab44-2797-86861f01d566/source/100x100bb.jpg");
    const [isTracker, setIsTracker] = React.useState(true);
    const [sortModel, setSortModel] = React.useState([
        {
          field: 'isTracker',
          sort: 'desc',
        },
      ]);

    useEffect(()=> {

        prevMatchesRef.current = matches

        if(prevMatches != matches) {
            if(matches) { // Tablet or bigger device
                setColumns(orig_columns)
            } else { // Mobile device
                setColumns(mobile_columns)
            }
        }
    })

    const handleClose = () => {
        setOpen(false);
      };

    const onRowSelection = (e) => {
        setDialogTitle(`${e.row.name}`)
        setDialogContent(`${e.row.domain}`)
        setDialogImage(e.row.image)
        if(e.row.isTracker) {
            setIsTracker(true)
        } else {
            setIsTracker(false)
    }
        setOpen(true)

    }

    const prevMatches = prevMatchesRef.current;

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{color: 'rgba(255,255,255,0.9'}}
            >
                <DialogTitle id="alert-dialog-title">
                    <img src={dialogImage} style={{borderRadius: '5px'}}/><br/>
                    {/* {dialogTitle} */}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Box sx={{ fontWeight: 'bold' }}>Domain:</Box>
                    {dialogContent}
                    
                </DialogContentText>
                </DialogContent>
            </Dialog>
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={props.connections}
                    columns={columns}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                    disableColumnMenu
                    pagination
                    autoPageSize
                    onCellClick={onRowSelection}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                />
            </div>
        </>
    )
}

export default ConnectionsTable;