import React, {useState,useEffect,useRef} from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const curated_lists = [
    'Disconnect default protection',
    'Facebook aggressive',
    'Amazon aggressive',
    'Google aggresive',
    'Fingerprinting aggressive'
  ];


function ListFilterComponent(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const prevMatchesRef = useRef(matches);
  const [componentWidth, setComponentWidth] = useState(matches ? '80%' : '100%');

  useEffect(()=> {

    prevMatchesRef.current = matches

    if(prevMatches != matches) {
        if(matches) { // Tablet or bigger device
            setComponentWidth('80%')
        } else { // Mobile device
            setComponentWidth('100%')
        }
    }
  })

  const prevMatches = prevMatchesRef.current;


  return (
    <div>
        <FormControl sx={{ m: 1, width: componentWidth, float: 'right', marginTop: '2em' }}>
            <InputLabel id="demo-multiple-checkbox-label">Blockable trackers - by Disconnect category</InputLabel>
            <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={props.selectedLists}
            onChange={(e) => props.onListChange(e)}
            input={<OutlinedInput label="Blockable trackers - by Disconnect category" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
            >
              <MenuItem disabled value="" style={{whiteSpace: 'break-spaces', backgroundColor: '#e5e5e5'}}>
                <Typography style={{fontWeight: '500'}}>
                Click the Disconnect lists below to see what would have been blocked.
                  </Typography>
              </MenuItem>
            {curated_lists.map((name) => (
                <MenuItem key={name} value={name}>
                <Checkbox checked={props.selectedLists.indexOf(name) > -1} />
                <ListItemText primary={name} />
                </MenuItem>
            ))}
            </Select>
        </FormControl>
    </div>
  )
}

export default ListFilterComponent;