import React from 'react';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';


class AppList extends React.Component {
    constructor(props) {    
        super(props);
        this.state = {
            applications: props.applications,
            selectedApp: props.selectedApp
        }
    }

    componentDidUpdate(prevProps) {

        if(prevProps.selectedApp !== this.props.selectedApp){
            this.setState({
                selectedApp: this.props.selectedApp
            })

        }

        if(prevProps.applications !== this.props.applications) {
            this.setState({
                applications: this.props.applications
            })
        }
      } 

    render() {

        var application_list = this.props.applications.map((a,i) => {
            var hasSelectedApp = this.state.selectedApp !== "";
            var cl = ""
            var node = ""
            
            if(hasSelectedApp) {
                cl = this.state.selectedApp === a.id ? 'selected' : 'not-selected'

                // if(i === this.state.selectedApp) {
                    node =
                        <>
                            <Tooltip title={a.name} key={i}>
                                <Button sx={{ minWidth: 150, height: 100 }} className={cl} onClick={(e) => this.props.appChange(a,i)}>
                                    <Badge className="btn" badgeContent={a.trackers.length} color="error">
                                        <img alt={a.name} src={a.image}></img>
                                    </Badge>
                                </Button>
                            </Tooltip>
                        </>
                // }
                
                
                
            } else {
                node = <Tooltip title={a.name} key={i}>
                <Button sx={{ minWidth: 150, height: 100 }} className={cl} onClick={(e) => this.props.appChange(a,i)}>
                    <Badge className="btn" badgeContent={a.trackers.length} color="error">
                        <img alt={a.name} src={a.image}></img>
                    </Badge>
                </Button>
            </Tooltip>
            }
            return(
                <>
                {node}            
                </>
                
            )
        })

        return (

            <div className="app-list">
                <Stack direction="row" spacing={2}>
                    {application_list}
                </Stack>
            </div>
        )
    }

}

export default AppList;
